@import '../../../styles/customMediaQueries.css';

.root {
  border-radius: var(--borderRadius20);

  &:hover {
    box-shadow: var(--boxShadowPopup);
    cursor: pointer;
  }
}

.listingWrapper {
  &:hover {
    text-decoration: none;
  }
}

.mobileListing {
  flex-direction: row;
  max-width: 100%;
  width: 100%;
  margin-bottom: -6px;

  & div[class*='infoWrapper'] {
    & > div:last-of-type {
      & > span:first-of-type {
        flex-wrap: wrap;

        & > h5 {
          margin: 6px 0;
        }
      }
    }
  }
}

.mobileListingImageWrapper {
  flex: 0 0 140px;
  min-width: 140px;
}

.paginatedListing {
  border-radius: var(--borderRadius20) var(--borderRadius20) 0 0;
}

.paginationInfo {
  background-color: var(--marketplaceColor);
  border-radius: 0 0 var(--borderRadius20) var(--borderRadius20);
  color: var(--matterColorLight);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
}

.paginationInfoMobile {
  margin-top: -6px;
}

.paginationPage {
  composes: h4 from global;

  font-weight: var(--fontWeightSemiBold);
  margin: 0;
}

.paginationArrow {
  display: inline-block;
  width: 36px;
  height: 40px;
  border: 0;
  background-position: center;
  border-bottom-left-radius: inherit;
  cursor: pointer;
  opacity: 0.5;
  transition: var(--transitionStyleButton);

  &:hover {
    opacity: 1;
  }
}

.paginationPrev {
  composes: paginationArrow;
  background-image: url('data:image/svg+xml;utf8,<svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg"><path d="M5.667 1c.094 0 .188.04.254.118.12.14.102.35-.038.47L1.85 5l4.032 3.412c.14.12.158.33.04.47-.12.14-.33.158-.47.04L1.117 5.254C1.043 5.19 1 5.098 1 5s.043-.19.118-.255L5.45 1.08c.064-.054.14-.08.217-.08" stroke="%23FFF" fill="%23FFF" fill-rule="evenodd"/></svg>');
}

.paginationNext {
  composes: paginationArrow;
  background-image: url('data:image/svg+xml;utf8,<svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg"><path d="M1.333 9c-.094 0-.188-.04-.254-.118-.12-.14-.102-.35.038-.47L5.15 5 1.118 1.588c-.14-.12-.158-.33-.04-.47.12-.14.33-.158.47-.04l4.334 3.667C5.957 4.81 6 4.902 6 5s-.043.19-.118.255L1.55 8.92c-.064.054-.14.08-.217.08" stroke="%23FFF" fill="%23FFF" fill-rule="evenodd"/></svg>');
}
