.root {
  position: relative;
  display: inline-block;
  width: 100%;
}

.label {
  composes: buttonFilter from global;
  composes: marketplaceSearchFilterLabelFontStyles from global;
}

.labelSelected {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  &:hover {
    background-color: var(--marketplaceColorDark);
    border: 1px solid var(--marketplaceColorDark);
  }
}
