@import '../../../styles/customMediaQueries.css';

.label {
  composes: buttonFilter from global;
  composes: marketplaceSearchFilterLabelFontStyles from global;
}

.labelSelected {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  &:hover {
    background-color: var(--marketplaceColorDark);
    border: 1px solid var(--marketplaceColorDark);
  }
}

.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
