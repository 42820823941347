@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCard {
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: calc(var(--topbarHeight) + 20px);

    @media (--viewportMedium) {
      margin-bottom: calc(var(--topbarHeightDesktop) + 20px);
    }
  }
}

.paginationContainer {
  max-width: 256px;
  margin: calc(var(--topbarHeight) * -1) auto 36px auto;

  @media (--viewportMedium) {
    margin: calc(var(--topbarHeightDesktop) * -1) auto 48px auto;
  }
}

.pagination {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  height: 0;
  overflow: hidden;
}

.paginationVisible {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  height: auto;
  overflow: visible;
  margin-bottom: calc(var(--topbarHeightDesktop) + 40px);

  @media (--viewportLarge) {
    margin-bottom: 24px;
  }
}

.suggestedListings {
  padding: 24px 0;
  border-top: 2px solid var(--colorGrey100);
}

.contactBanner {
  margin-bottom: calc(var(--topbarHeight) + 20px);

  & > div {
    padding: 0;
    margin: 0;

    & > h2 {
      display: none;
    }
  }

  @media (--viewportMedium) {
    margin-bottom: calc(var(--topbarHeightDesktop) + 20px);
  }
}
