@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.searchResultSummary {
  composes: h3 from global;
  margin-top: 6px;

  display: flex;
  justify-content: space-between;

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.filters {
  display: flex;
  gap: 12px;
  width: calc(100% + 44px);
  left: -24px;
  padding: 0 24px 12px 24px;
  margin-left: -24px;
  overflow-x: scroll;
  overflow-y: hidden;
  z-index: 0;

  & > *:not(:last-child) {
    height: auto;
    white-space: nowrap;
    word-break: keep-all;
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 4px 0 24px 0;
}

.mapIcon {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Filter button */
  composes: buttonFilter from global;
  composes: marketplaceSearchFilterLabelFontStyles from global;

  /* background map image */
  background-image: url(./images/map_icon216x105.png);
  background-size: cover;
  width: auto;
  margin-left: 12px;
}

.modalContainer {
  width: 100%;
  padding: 80px 24px;

  @media (--viewportSmall) {
    margin: 0 46px;
  }
}

.modalContent {
  display: block;
}

.modalHeadingWrapper {
  display: flex;
  justify-content: flex-end;
}

.scrollLayer {
  composes: marketplaceModalRootStyles from global;

  /* Add default background color to avoid bouncing scroll showing the
 page contents from behind the modal. */
  background-color: var(--matterColorLight);

  /* Additional styles for the modal window, dimming the background and positioning the modal */
  min-height: 100vh;
  overflow: auto;
  z-index: -1;

  @media (--viewportMedium) {
    background-color: var(--matterColorLight);
    padding: 0;
  }
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.filtersWrapper {
  /* add bottom padding so that the last filter won't be hidden
   * under the mobile safari bottom bar or the "Show results"
   * button bar */
  padding-bottom: 120px;
}

.showListingsContainer {
  position: fixed;
  bottom: 0;
  left: 0;

  display: flex;
  width: 100vw;
  padding: 18px 24px;

  background: var(--colorWhite);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 11;
}

.showListingsButton {
  background-color: var(--marketplaceColor);
  display: inline-block;
  border-radius: var(--borderRadius);
  padding: 8px 16px;
  width: 260px;
  margin: 0 auto;
}

.clearFilters {
  composes: marketplaceTinyFontStyles from global;
  border: none;
  margin-left: auto;
  text-decoration: underline;
  cursor: pointer;
  color: var(--matterColor4A);
}
