.fullArea {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.activeLabel {
  z-index: 1;
}

.labelContainer {
  &:hover {
    z-index: 1;
  }

  &:focus {
    outline: none;
  }
}

.infoCardContainer {
  z-index: 1;
  font-family: var(--fontFamily);
}

.infoCardContainerMobile {
  width: calc(100% - 32px);
  margin-left: 16px;
  position: fixed !important;
  top: initial !important;
  bottom: calc(env(safe-area-inset-bottom) + 36px);
  right: 0;
  transform: none !important;
}
