@import '../../styles/customMediaQueries.css';

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: var(--zIndexTopbar);
  }
}

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.container {
  width: 100%;
  z-index: 0;

  @media (--viewportMedium) {
    padding-top: calc(var(--topbarHeightDesktop) + var(--topbarHeightExpandedDesktop) + 24px);
  }

  @media (--viewportLarge) {
    display: flex;
    flex-grow: 1;
  }
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 50%;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportLarge) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: calc(var(--topbarHeightDesktop) + var(--topbarHeightExpandedDesktop) + 24px);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop) - var(--topbarHeightExpandedDesktop) - 24px);
  }
}

/* ------------- MainPanel classes ------------- */

.searchResultContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  background: var(--matterColorEE);

  padding: 24px var(--SearchPage_sidePaddings) 0 var(--SearchPage_sidePaddings);

  @media (--viewportMedium) {
    padding: 24px var(--SearchPage_sidePaddingsDesktop) 0 var(--SearchPage_sidePaddingsDesktop);
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }
}

.selectedActivityTitle {
  margin-top: 0;
  font-size: 16px;

  & > span {
    color: var(--marketplaceColor);
    margin-right: 4px;
  }

  @media (--viewportMedium) {
    /* '3 *' because the form inputs are the same height as topbar  */
    padding-top: calc(3 * var(--topbarHeight));
  }

  @media (--viewportLarge) {
    padding-top: 0;
  }
}

.searchResultsWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.searchFiltersPrimary {
  display: none;

  @media (--viewportLarge) {
    margin-bottom: 24px;
    display: flex;
  }
}

.searchFiltersMobile {
  @media (--viewportLarge) {
    display: none;
  }
}

.searchFiltersMobileList {
  padding: 16px 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
  width: 100%;
}

.highlightListing {
  border: 2px solid var(--marketplaceColor);
  box-shadow: var(--boxShadowListingCard);
}
