@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
  width: max-content;

  @media (--viewportLarge) {
    width: 100%;
  }
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-bottom: 30px;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;
}

.fieldCheckbox {
  margin: 0;
}
