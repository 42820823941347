@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.longInfo {
  flex-wrap: wrap;
}

.filters {
  display: flex;
  gap: 16px;

  overflow-x: scroll;
  overflow-y: hidden;
  position: absolute;
  left: calc(-1 * var(--SearchPage_sidePaddings));
  width: calc(100% + (2 * var(--SearchPage_sidePaddings)));
  padding: 0px var(--SearchPage_sidePaddings) 16px var(--SearchPage_sidePaddings);

  & > * {
    flex: 1 0 calc(25% - 12px);

    @media (--viewportLarge) {
      flex: 0 0 calc(20% - 14px);
    }

    @media (--viewportXLarge) {
      max-width: 220px;
    }
  }

  & > *:not(:last-child) {
    height: auto;
    white-space: nowrap;
    word-break: keep-all;
  }

  @media (--viewportLarge) {
    flex-wrap: wrap;

    overflow-x: visible;
    overflow-y: visible;
    position: unset;
    left: unset;
    width: unset;
    padding: 0px;
  }
}

.searchOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 58px;
  order: 1;

  @media (--viewportLarge) {
    order: 0;
    margin-top: 0;
    margin-bottom: 24px;
  }
}

.searchResultSummary {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor4A);
  margin: 0 12px 0 0;
}

.noSearchResults {
  composes: h4 from global;

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-basis: 100%;
  flex-shrink: 0;
}

.loadingResults {
  composes: h4 from global;

  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  margin: 0;

  background-color: var(--matterColorEE);
  opacity: 0.5;
}

.resultsFound {
  white-space: nowrap;
}

.searchFiltersPanelClosed {
  composes: buttonSecondary from global;
  composes: marketplaceSearchFilterLabelFontStyles from global;

  display: inline-block;
  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.searchFiltersPanelOpen {
  composes: buttonDefault from global;
  composes: marketplaceSearchFilterLabelFontStyles from global;

  font-weight: var(--fontWeightSemiBold);

  display: inline-block;
  padding: 9px 16px 9px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.clearFilters {
  composes: marketplaceTinyFontStyles from global;
  border: none;
  margin-left: auto;
  text-decoration: underline;
  cursor: pointer;
  color: var(--matterColor4A);
}
