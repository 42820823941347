@import '../../../styles/customMediaQueries.css';

.root {
  /* Size from content */
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  border: 0;

  &:hover {
    & .details,
    & .caret {
      cursor: pointer;

      /* Same as active */
      background-color: var(--marketplaceColor);
      border-color: var(--marketplaceColor);
      color: var(--colorWhite);
      box-shadow: var(--boxShadowPopup);
    }
  }
}

.details {
  /**
   * Since caret is absolutely positioned,
   * label must have relative to be included to the same rendering layer
   */
  position: relative;

  /* Font */
  composes: p from global;
  font-weight: var(--fontWeightBold);
  margin-top: 0;
  margin-bottom: 0;

  padding: 6px 14px;

  /* Coloring */
  background-color: var(--matterColorLight);
  color: var(--matterColorDark);

  /* Borders */
  border-radius: var(--borderRadius20);
  border-style: solid;
  border-color: var(--colorGrey100);
  border-width: 1px;
  box-shadow: var(--boxShadowPopupLight);

  transition: var(--transitionStyleButton);

  /* Overwrite dimensions from font styles */
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.detailsActive {
  /* Same as hover */
  background-color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
  color: var(--colorWhite);
  box-shadow: var(--boxShadowPopup);
}

.caretShadow {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  /* Caret should have same box-shadow as label */
  box-shadow: var(--boxShadowPopupLight);
}

.caret {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  /* Caret should have same bg-color as label */
  background-color: var(--colorWhite);

  transition: var(--transitionStyleButton);
}

.caretActive {
  /* Same as hover */
  background-color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
}

.tooltip {
  padding: 0;
  background: transparent !important;
  border: 0 !important;
  opacity: 1 !important;
}

.content {
  text-align: center;

  position: relative;

  /* Font */
  composes: p from global;
  font-weight: var(--fontWeightBold);
  margin-top: 0;
  margin-bottom: 0;

  padding: 6px 14px;

  /* Coloring */
  background-color: var(--matterColorLight);
  color: var(--matterColorDark);

  /* Borders */
  border-radius: 19px;
  border-style: solid;
  border-color: var(--colorGrey100);
  border-width: 1px;
  box-shadow: var(--boxShadowPopupLight);

  /* Overwrite dimensions from font styles */
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.listingPrice {
  composes: h5 from global;

  /* Font */
  composes: h3 from global;

  font-weight: var(--fontWeightBold);
  color: var(--matterColorDark);

  border-radius: var(--borderRadius20);

  padding: 6px 16px;
  border: 0;
  margin: 0;
  outline: none;
  transition: var(--transitionStyleButton);
  width: 100%;
  border-radius: var(--borderRadius);
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }
}

.paginationInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;

  border-top: 1px solid var(--colorGrey300);
  margin-top: 6px;
}

.paginationPage {
  composes: h4 from global;

  font-weight: var(--fontWeightSemiBold);
  margin: 0;
}

.paginationArrow {
  display: inline-block;
  width: 36px;
  height: 40px;
  border: 0;
  background-position: center;
  border-bottom-left-radius: inherit;
  cursor: pointer;
  opacity: 0.5;
  transition: var(--transitionStyleButton);

  &:hover {
    opacity: 1;
  }
}

.paginationPrev {
  composes: paginationArrow;
  background-image: url('data:image/svg+xml;utf8,<svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg"><path d="M5.667 1c.094 0 .188.04.254.118.12.14.102.35-.038.47L1.85 5l4.032 3.412c.14.12.158.33.04.47-.12.14-.33.158-.47.04L1.117 5.254C1.043 5.19 1 5.098 1 5s.043-.19.118-.255L5.45 1.08c.064-.054.14-.08.217-.08" stroke="currentColor" fill="currentColor" fill-rule="evenodd"/></svg>');
}

.paginationNext {
  composes: paginationArrow;
  background-image: url('data:image/svg+xml;utf8,<svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg"><path d="M1.333 9c-.094 0-.188-.04-.254-.118-.12-.14-.102-.35.038-.47L5.15 5 1.118 1.588c-.14-.12-.158-.33-.04-.47.12-.14.33-.158.47-.04l4.334 3.667C5.957 4.81 6 4.902 6 5s-.043.19-.118.255L1.55 8.92c-.064.054-.14.08-.217.08" stroke="currentColor" fill="currentColor" fill-rule="evenodd"/></svg>');
}
