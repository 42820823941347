@import '../../../styles/customMediaQueries.css';

.root {
  height: auto;
}

.icon {
  position: relative;
  bottom: 1px;
  margin-right: 5px;
  stroke: var(--matterColor4A);

  @media (--viewportMedium) {
    bottom: 2px;
  }
}

.menuLabel {
  composes: buttonFilter from global;
  composes: marketplaceSearchFilterLabelFontStyles from global;

  &:focus > .icon {
    stroke: var(--matterColorLight);
  }
}

.menuContent {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  min-width: 200px;
  margin-top: 7px;
  background-color: var(--matterColorLight);
  padding: 0 0 18px 0;
  overflow: hidden;

  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: var(--marketplaceFilterBorderRadius);
  transition: var(--transitionStyleButton);
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--matterColorDark);
  transition: width var(--transitionStyleButton);
}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 7px);
  width: 6px;
  background-color: var(--marketplaceColor);
}

.menuHeading {
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);
  margin-top: 24px;
  margin-left: 30px;
  margin-right: 30px;
}

.menuItem {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--matterColor);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 4px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }

  &:disabled {
    color: var(--matterColorAnti);
    cursor: default;
  }
  &:disabled:hover .menuItemBorder {
    width: 0;
  }
}

.menuItemSelected {
  color: var(--marketplaceColor);
}

.clearMenuItem {
  composes: h4 from global;

  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  transition: width var(--transitionStyleButton);

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}
